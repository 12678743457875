let slotTypes =
    [
        { id: 1, name: "横幅" },
        { id: 2, name: "插屏" },
        { id: 3, name: "开屏" },
        { id: 4, name: "信息流" },
        { id: 5, name: "视频" },
    ];


let slotStatus =
    [
        { id: 0, name: "禁用", type: "danger" },
        { id: 1, name: "测试", type: "warning" },
        { id: 2, name: "开启", type: "default" },
    ];
let appTypes =
    [
        { id: 0, name: "Android" },
        { id: 1, name: "iOS" },
        { id: 2, name: "WAP" },
        { id: 3, name: "快应用" },
    ];

let auditStatus =
    [
        { id: 0, name: "待审核" },
        { id: 1, name: "审核通过" },
        { id: 2, name: "审核拒绝" },
    ];

let slotSettingStatus =
    [
        { id: 0, name: "关闭", type: "info" },
        { id: 1, name: "开启", type: "success" },
    ];
export default {
    slotTypes,
    slotStatus,
    appTypes,
    auditStatus,
    slotSettingStatus
}